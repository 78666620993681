import React from "react"
import MenuLink from "./MenuLink"
import styled from "styled-components"

const UlTag = styled.ul`
    list-style-type: none;
    display: flex;
    &:hover li a {
        opacity: 0.5;
    }
    &:hover li:hover a {
        opacity: 1;
    }

    @media screen and (min-width: 1600px) {
        font-size: 18px;
    }

    @media screen and (max-width: 750px) {
        display: none
    }
`

const Menu = ({ menuLinksArray }) => (
    <UlTag>
        {menuLinksArray.map(menuLink => (
            <MenuLink linkTitle={menuLink.title} linkURL={menuLink.URL} key={menuLink.title}></MenuLink>
        ))}
    </UlTag>
)

export default Menu
