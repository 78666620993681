import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components'

const H1Tag = styled.h1`
    font-size: 22px;
    color: #000;
    letter-spacing: 2.7px;

    @media screen and (min-width: 1600px) {
        font-size: 24px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 20px;
    }

    @media screen and (max-width: 750px) {
        font-size: 18px;
    }
`

const Logo = ({ siteTitle }) => (
    <Link
        to="/"
        style={{
            textDecoration: 'none'
        }}
    >
        <H1Tag>{siteTitle}</H1Tag>
    </Link>
)

export default Logo
