import React from "react"
import Menu from "./Menu"
import MobileMenu from "./MobileMenu"
import Logo from "./Logo"
import styled from "styled-components"

const HeaderTag = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 20px 2.43vw 0;

  @media screen and (max-width: 750px) {
    padding: 20px 4vw 0;
  }
`

const Header = ({ siteTitle, headerPosition }) => (
  <HeaderTag className="max-width-1600" style={{ position: headerPosition }}>
    <Logo siteTitle={siteTitle}></Logo>

    <Menu menuLinksArray={[
      {
        title: 'PROJECTS',
        URL: '/projects/'
      },
      {
        title: 'EVENTS',
        URL: '/events/'
      },
      {
        title: 'DIARY',
        URL: '/diary/'
      },
      {
        title: 'ABOUT',
        URL: '/about/'
      }
    ]}></Menu>
    <MobileMenu menuLinksArray={[
      {
        title: 'PROJECTS',
        URL: '/projects/'
      },
      {
        title: 'EVENTS',
        URL: '/events/'
      },
      {
        title: 'DIARY',
        URL: '/diary/'
      },
      {
        title: 'ABOUT',
        URL: '/about/'
      }
    ]} />

  </HeaderTag>
)

export default Header

