import React, { useState } from "react"
import styled from "styled-components"
import ReactModal from "react-modal"
import { Link } from 'gatsby'
import '../styles/mobile-menu.css'

const MobileMenu = ({ menuLinksArray }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
    <>
        <ul id="mobile-menu"> 
            <li><button onClick={() => setIsMenuOpen(true)}>Menu</button></li>
        </ul>

        <ReactModal
            isOpen={isMenuOpen}
            onRequestClose={() => setIsMenuOpen(false)}
            contentLabel={"MobileMenu"}
            closeTimeoutMS={150}
            className="modal_content"
            overlayClassName="modal_overlay"
            ariaHideApp={false}
        >
            <div id="close-button_container">
                <button 
                    id="close-button"
                    onClick={() => setIsMenuOpen(false)}>
                    close
                </button>
            </div>
            <ul id="mobile-menu_content">
                {menuLinksArray.map(menuLink => (
                    <Link className="link" to={menuLink.URL} key={menuLink.title}>{menuLink.title}</Link>
                ))}
            </ul>
        </ReactModal>
    </>
    )
}

export default MobileMenu
