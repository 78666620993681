import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const LinkTag = styled(Link)`
    color: #000;
    text-decoration: none;
    fontWeight: 400;
    letter-spacing: 2px;
`

const LiTag = styled.li`
    margin-right: 60px;
    display: flex;
    align-items: flex-end;
    &:last-child {
        margin-right: 0;
    }
`

const MenuLink = ({ linkTitle, linkURL }) => (
    <LiTag>
        <LinkTag to={linkURL}>
            {linkTitle}
        </LinkTag>
    </LiTag>
)

export default MenuLink
